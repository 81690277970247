import * as React from "react";
import { useEffect } from "react";
import { track } from "@amplitude/analytics-browser"

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import MissionMessage from "../../components/mission";


const MissionPage = () => {
  useEffect(() => {
    track('MissionPage Visited');
  },[]);
  return (
    <Layout>
      <Seo title="Our Mission" />
      <MissionMessage />
    </Layout>
  );
}

export default MissionPage;
